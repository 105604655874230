import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
  InputLabel,
  Box
} from '@mui/material';

interface MenuItemProps extends MuiMenuItemProps {
  value: string;
}

interface SelectProps extends Omit<MuiSelectProps, 'placeholder'> {
  label?: string;
  options?: MenuItemProps[];
  placeholder: string; // Add placeholder property here
}

export const Select = ({ label, options, placeholder, ...rest }: SelectProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <InputLabel shrink htmlFor={label}>
        {label}
      </InputLabel>
      <MuiSelect {...rest}>
        <MuiMenuItem disabled value="">
          {placeholder}
        </MuiMenuItem>
        {options?.map((option) => (
          <MuiMenuItem key={option.value} {...option} />
        ))}
      </MuiSelect>
    </Box>
  );
};
