import { CustomPdfViewer } from 'features/src/organisms/CustomPdfViewer/CustomPdfViewer';
import { useLocation } from 'react-router-dom';

type PdfViewerState = {
	source: string;
	enableDownload: boolean;
	filename?: string;
};

const ViewDocument = () => {
	const { state } = useLocation();
	const { source, enableDownload, filename } = state as PdfViewerState;

	return <CustomPdfViewer source={source} enableDownload={enableDownload} filename={filename} />;
};

export default ViewDocument;
