import { useTheme } from '@mui/material';
import { IconButton } from 'features';

export const CircleIconButton = ({ onClick }: { onClick: () => void }) => {
	const theme = useTheme();

	return (
		<IconButton
			onClick={onClick}
			sx={{
				borderRadius: 4,
				backgroundColor: theme.colors.error.lighter,
				mr: 2,
				'& .MuiSvgIcon-root': {
					color: theme.colors.error.main,
					fontSize: '1.25rem',
				},
				'&.MuiButtonBase-root:hover': {
					backgroundColor: theme.colors.error.light,
				},
			}}
			icon="UilTimes"
			size="small"
		/>
	);
};
