import {
	FormControl,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
} from '@mui/material';
import { ReadableLibraryDocumentType, LibraryDocumentType } from 'features';
import React, { useState } from 'react';
import { CircleIconButton } from './CircleIconButton';

export const DocumentTypeSelect = ({
	width,
	onChange,
	labelComponent,
	value,
	disabled,
	error,
}: {
	width: number | string | undefined;
	onChange: (value?: LibraryDocumentType) => void;
	labelComponent?: React.ComponentType<{
		id?: string;
	}>;
	value?: LibraryDocumentType | null;
	disabled?: boolean;
	error?: boolean;
}) => {
	const documentTypes = (
		Object.values(LibraryDocumentType) as Array<LibraryDocumentType>
	).filter((v) => !isNaN(Number(v)));

	const [showIcon, setShowIcon] = useState(false);

	const LabelComponent = labelComponent ?? InputLabel;

	return (
		<FormControl sx={{ width: width ?? 'auto' }}>
			<LabelComponent id="document-type-label">Type</LabelComponent>
			<Select
				onChange={(e) => onChange(e.target.value as LibraryDocumentType)}
				sx={{ mb: 1 }}
				labelId="document-type-label"
				value={value ?? ''}
				onMouseOver={() => setShowIcon(value !== undefined)}
				onMouseOut={() => setShowIcon(false)}
				input={
					<OutlinedInput
						endAdornment={
							showIcon && <CircleIconButton onClick={() => onChange()} />
						}
						label={!labelComponent ? 'Type' : ''}
					/>
				}
				disabled={disabled ?? false}
				error={error ?? false}
			>
				{documentTypes.map((item, i) => (
					<MenuItem value={item} key={`type-${i}`}>
						{ReadableLibraryDocumentType[item]}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
