import { Stepper, Step, StepLabel } from '@mui/material';

export type HorizontalStepperProps = {
	steps: {
		name: string;
		component: JSX.Element | JSX.Element[];
	}[];
	currentStep: number;
};

export const HorizontalStepper = ({
	steps,
	currentStep,
}: HorizontalStepperProps) => {
	return (
		<>
			<Stepper
				activeStep={currentStep}
				alternativeLabel
				sx={{
					backgroundColor: 'transparent',
					pb: 4,
				}}
			>
				{steps.map((step) => {
					const stepProps: { completed?: boolean } = {};
					const labelProps: {
						optional?: React.ReactNode;
					} = {};
					return (
						<Step key={step.name} {...stepProps}>
							<StepLabel
								sx={{
									'.MuiStepIcon-text': {
										display: 'none',
									},
								}}
								{...labelProps}
							>
								{step.name}
							</StepLabel>
						</Step>
					);
				})}
			</Stepper>
			{steps[currentStep].component}
		</>
	);
};
