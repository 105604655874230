import { CssBaseline } from '@mui/material';
import router from 'src/router/router';
import { ToastContainer } from 'react-toastify';
import { useRoutes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { AbilityContext } from './contexts/CanContext';
import defineAbilityFor from './config/ability';
import store from './store/store';
import { Suspense, useEffect, useState } from 'react';
import { logout, useAuth } from './features/authentication';
import { sentryConfig } from './config/sentry';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SuspenseLoader, ThemeProviderWrapper, initMonitoring } from 'features';

initMonitoring(sentryConfig, 'njmypx4ah5');

function App() {
	const content = useRoutes(router);
	const {
		login,
		loading: userLoading,
		user: firebaseUser,
		getUser,
	} = useAuth();

	const [loading, setLoading] = useState(userLoading);

	const user = store.getState().user;
	const ability = defineAbilityFor(user.userType ?? '');

	useEffect(() => {
		const extractTokenAndSignIn = async () => {
			const urlParams = new URLSearchParams(window.location.search);
			const idToken = urlParams.get('idToken');

			if (idToken) {
				try {
					localStorage.setItem('idToken', decodeURIComponent(idToken));

					await login(decodeURIComponent(idToken));
				} catch (error) {
					console.error('Error signing in with custom token:', error);
					// Handle the error as needed
				}
			} else {
				try {
					await getUser();
				} catch {
					setLoading(true);
					await logout();
				}
			}
		};

		if (loading && !firebaseUser) extractTokenAndSignIn();
	}, []);

	return loading && !firebaseUser ? (
		<ThemeProviderWrapper>
			<Suspense fallback={<SuspenseLoader />}>
				<SuspenseLoader />
			</Suspense>
		</ThemeProviderWrapper>
	) : (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
			<AbilityContext.Provider value={ability}>
				<ThemeProviderWrapper>
					<CssBaseline />
					<ToastContainer
						hideProgressBar={true}
						theme={'light'}
						position="bottom-left"
					/>
					{content}
				</ThemeProviderWrapper>
			</AbilityContext.Provider>
		</LocalizationProvider>
	);
}

export default App;
