import { pnApi } from '../pnBaseApi';
import { RestApiResponseWithData, RestApiResponseWithPagination } from '../types/apiResponse.types';
import { MenuItem } from '../types/common.types';
import { SearchQueryParams, TmsOrCaQuestionQueryParams } from '../types/queryParams.types';
import { QuestionTMSRequest, Question4U, Update4uQuestionStatus, QuestionCA, UpdateTmsOrCaQuestionStatus, QuestionTMS } from '../types/question.types';

const questionsApi = pnApi.injectEndpoints({
	endpoints: (builder) => ({
		createQuestion: builder.mutation<void, QuestionTMSRequest>({
			query(params) {
				const data = new FormData();
				data.append('type', params.type as string);
				data.append('subject', params.subject as string);
				data.append('message', params.message as string);
				data.append('hasImages', params.hasImages?.toString() as string);
				data.append('platform', params.platform?.toString() as string);

				params.files?.forEach((file) => {
					//@ts-ignore
					data.append('files', file as Blob, file.name);
				});

				return {
					url: 'Question',
					method: 'POST',
					body: data,
					formData: true,
				};
			},

			invalidatesTags: ['QuestionsTms'],
		}),

		updateTmsOrCaQuestionStatus: builder.mutation<
			RestApiResponseWithData<QuestionCA | QuestionTMS>,
			UpdateTmsOrCaQuestionStatus
		>({
			query(body) {
				return {
					url: 'Question/Status',
					method: 'PUT',
					body,
				};
			},

			invalidatesTags: ['QuestionsCa', 'QuestionsTms'],
		}),

		getAllCAQuestions: builder.query<
			RestApiResponseWithPagination<QuestionCA[]>,
			TmsOrCaQuestionQueryParams
		>({
			query: (args) => {
				return {
					url: 'Question',
					params: args,
				};
			},
			providesTags: ['QuestionsCa'],
		}),

		getAllTMSQuestions: builder.query<
			RestApiResponseWithPagination<QuestionTMS[]>,
			TmsOrCaQuestionQueryParams
		>({
			query: (args) => {
				return {
					url: 'Question',
					params: args,
				};
			},
			providesTags: ['QuestionsTms'],
		}),

		getTmsorCaQuestionStatusDropdownValues: builder.query<
			RestApiResponseWithData<MenuItem[]>,
			void
		>({
			query: () => {
				return {
					url: '/Question/QuestionStatusDropdownValues',
					method: 'GET',
				};
			},
		}),

		getAll4UQuestions: builder.query<
			RestApiResponseWithPagination<Question4U[]>,
			SearchQueryParams
		>({
			query: (args) => {
				return {
					url: 'Question/4U',
					params: args,
				};
			},
			providesTags: ['Questions4u'],
		}),

		update4uQuestionStatus: builder.mutation<
			RestApiResponseWithData<Question4U>,
			Update4uQuestionStatus
		>({
			query(body) {
				return {
					url: 'Question/Status/4U',
					method: 'PUT',
					body,
				};
			},

			invalidatesTags: ['Questions4u'],
		}),

		get4uQuestionStatusDropdownValues: builder.query<
			RestApiResponseWithData<MenuItem[]>,
			void
		>({
			query: () => {
				return {
					url: '/Question/QuestionStatusDropdownValues/4U',
					method: 'GET',
				};
			},
		}),
	}),
	
});

export const { 
	useCreateQuestionMutation,
	useUpdateTmsOrCaQuestionStatusMutation,
	useGetAllTMSQuestionsQuery,
	useGetAllCAQuestionsQuery,
	useGetAll4UQuestionsQuery,
	useGetTmsorCaQuestionStatusDropdownValuesQuery,
	useUpdate4uQuestionStatusMutation,
	useGet4uQuestionStatusDropdownValuesQuery,
} = questionsApi;
