import {
	BaseQueryFn,
	FetchArgs,
	FetchBaseQueryError,
	FetchBaseQueryMeta,
	createApi,
	fetchBaseQuery,
	retry,
} from '@reduxjs/toolkit/query/react';
import { ApiStatus } from 'src/services/types/apiStatus.types';
import { User } from './types/user.types';
import {
	RestApiResponse,
	RestApiResponseWithData,
} from './types/apiResponse.types';
import store from 'src/store/store';
import { toast } from 'react-toastify';
import { Mutex } from 'async-mutex';
import { logout, refreshToken } from 'src/features/authentication';

const mutex = new Mutex();

function globalHeaders(headers: any): void {
	const user = store.getState().user;

	if (user.accessToken) {
		headers.set('Authorization', `Bearer ${user.accessToken}`);
	}
	return headers;
}

const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_BASE_API_URL ?? '',
	prepareHeaders: globalHeaders,
});

const staggeredBaseQuery = retry(baseQuery, {
	maxRetries: 2,
});

const baseQueryWithTokenExpirationCheck: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError,
	{},
	FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
	// Wait until the mutex is available without locking it.
	await mutex.waitForUnlock();

	let result = await staggeredBaseQuery(args, api, extraOptions);

	if (
		result.error &&
		(result.error.status === 401 || result.error.status === 403)
	) {
		// Checking whether the mutex is locked.
		if (!mutex.isLocked()) {
			const release = await mutex.acquire();

			try {
				// Get a new access token.
				const refreshResult = await refreshToken();

				if (
					refreshResult &&
					refreshResult.accessToken &&
					refreshResult.refreshToken
				) {
					// Retry the initial query.
					result = await staggeredBaseQuery(args, api, extraOptions);
				} else {
					throw new Error('Refresh Token Error');
				}
			} catch {
				toast.dismiss();
				toast.error('Session expired. Please log in again.');
				setTimeout(() => {
					logout();
				}, 2000);

				// We can bail out of retries if we know it is going to be redundant - not authenticated at all.
				retry.fail(result.error);
			} finally {
				// Release must be called once the mutex should be released again.
				release();
			}
		} else {
			// Wait until the mutex is available without locking it.
			await mutex.waitForUnlock();
			result = await staggeredBaseQuery(args, api, extraOptions);
		}
	}
	if (result.error && result.error.status === 401) {
		//The result is still 401 after a retry - logging out
		logout();
	}

	return result;
};

export const pnApi = createApi({
	reducerPath: 'pnApi',
	baseQuery: baseQueryWithTokenExpirationCheck,
	refetchOnMountOrArgChange: 60,
	tagTypes: [
		'Farms',
		'Farm',
		'Blocks',
		'Block',
		'CropClasses',
		'CropClass',
		'CropSubClasses',
		'CropSubClass',
		'Cultivars',
		'Cultivar',
		'Crops',
		'Crop',
		'TechnicalAdvisors',
		'CropAdvisors',
		'CropAdvisor',
		'SoilSamples',
		'SoilSample',
		'LeafSamples',
		'LeafSample',
		'FarmBlockCropInfo',
		'Business',
		'TMSUsers',
		'CAUsers',
		'User',
		'CropCultivar',
		'BcCandidates',
		'User4U',
		'UserTeam4U',
		'LibraryItems',
		'LibraryItem',
		'Questions4u',
		'QuestionsCa',
		'QuestionsTms',
		'TimesOfApplication',
	],
	endpoints: (builder) => ({
		getApiStatus: builder.query<RestApiResponse, void>({
			query: () => 'SystemStatus',
		}),

		getCurrentUser: builder.query<RestApiResponseWithData<User>, string>({
			query(token) {
				return {
					url: 'User/CurrentUser',
					method: 'Get',
					headers: { Authorization: `Bearer ${token}` },
				};
			},
		}),
	}),
});

export const { useGetApiStatusQuery } = pnApi;
