import { FormControl } from '@mui/material';
import { VirtualizedAutoComplete } from 'features';
import { Product, ProductsQueryType } from '../../../types/product.types';

export const DocumentProductsSelect = ({
	onChange,
	value,
	width,
	labelComponent,
	tagQuery,
	disabled,
}: {
	onChange: (productId: string) => void;
	width: number | string | undefined;
	value?: string | null;
	labelComponent?: React.ComponentType<{
		id?: string;
		children: string;
	}>;
	tagQuery: ProductsQueryType;
	disabled?: boolean;
}) => {
	const { data, isLoading, isFetching } = tagQuery({});

	return (
		<FormControl sx={{ width: width ?? 'auto' }}>
			<VirtualizedAutoComplete
				value={data?.data?.find((x: any) => x.no === value)}
				disabled={isLoading && isFetching && (disabled ?? false)}
				options={data?.data ?? []}
				getOptionLabel={(option: Product) => option.description}
				label={'Product'}
				onChange={(_, product) => onChange(product?.no ?? '')}
				onClick={() => {}}
				labelComponent={labelComponent}
			/>
		</FormControl>
	);
};
