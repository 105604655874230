import {
	Middleware,
	MiddlewareAPI,
	isRejectedWithValue,
} from '@reduxjs/toolkit';

export const rtkQueryErrorLogger: Middleware =
	(api: MiddlewareAPI) => (next) => async (action) => {
		if (isRejectedWithValue(action)) {
			console.log('error: query failed');
		}

		return next(action);
	};
