import Box from '@mui/material/Box/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography/Typography';
import { useGetApiStatusQuery } from 'src/services/pnBaseApi';

export default function SystemOverviewPage() {
  const { data } = useGetApiStatusQuery();

  const frontendData = {
    buildNumber: process.env.REACT_APP_BUILD_NUMBER
      ? process.env.REACT_APP_BUILD_NUMBER
      : 'N/A',
    buildId: process.env.REACT_APP_BUILD_ID
      ? process.env.REACT_APP_BUILD_ID
      : 'N/A',
    apiUrl: process.env.REACT_APP_BASE_API_URL
      ? process.env.REACT_APP_BASE_API_URL
      : 'N/A',
    appUrl: process.env.REACT_APP_APP_BASE_URL
      ? process.env.REACT_APP_APP_BASE_URL
      : 'N/A',
    firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY
      ? process.env.REACT_APP_FIREBASE_API_KEY
      : 'N/A',
    firebaseAuthDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
      ? process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
      : 'N/A'
  };

  console.log('manual-deployment-check-1.0')

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100%',
        height: '100%'
      }}
    >
      <Card
        sx={{
          backgroundColor: '#509E2F',
          width: '50%',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)'
        }}
      >
        <Stack
          direction="column"
          spacing={2}
          sx={{
            p: 2
          }}
        >
          <Card
            sx={{
              width: '100%',
              backgroundColor: '#FFFFFF',
              color: '#000000'
            }}
          >
            <Typography
              variant="h4Bold"
              sx={{
                textAlign: 'center',
                p: 2
              }}
            >
              Application Status
            </Typography>
            <Typography
              variant="h6"
              sx={{
                textAlign: 'center',
                p: 0.5
              }}
            >
              {`API URL: ${frontendData.apiUrl}`}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                textAlign: 'center',
                p: 0.5
              }}
            >
              {`Build ID: ${frontendData.buildId}`}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                textAlign: 'center',
                p: 0.5
              }}
            >
              {`Build number: ${frontendData.buildNumber}`}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                textAlign: 'center',
                p: 0.5
              }}
            >
              {`App Url: ${frontendData.appUrl}`}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                textAlign: 'center',
                p: 0.5
              }}
            >
              {`Firebase API key: ${frontendData.firebaseApiKey}`}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                textAlign: 'center',
                p: 0.5
              }}
            >
              {`Firebase Auth Domain: ${frontendData.firebaseAuthDomain}`}
            </Typography>
          </Card>
          <Card
            sx={{
              width: '100%',
              backgroundColor: '#FFFFFF',
              color: '#000000'
            }}
          >
            <Typography
              variant="h4Bold"
              sx={{
                textAlign: 'center',
                pt: 2
              }}
            >
              API Status
            </Typography>
            <Typography
              variant="h6"
              sx={{
                textAlign: 'center',
                pb: 1
              }}
            >
              {data?.message}
            </Typography>
            {data &&
              Object.keys(data).map((key) => {
                return (
                  <Typography
                    variant="h6"
                    key={key}
                    sx={{
                      textAlign: 'center',
                      p: 0.5
                    }}
                  >
                    {`${key}: ${
                      data?.[key as keyof typeof data]
                    }`}
                  </Typography>
                );
              })}
          </Card>
        </Stack>
      </Card>
    </Box>
  );
}
