import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, colors, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

interface Tab {
	tabLabel: string;
	tabContent: React.ReactNode;
	tabValue: string;
	error?: boolean;
	disabled?: boolean;
}

interface CustomTabProps {
	tabs: Tab[];
	activeTabIndex?: number;
	setActiveTabIndex?: (activeIndex: number) => void;
}

export const CustomTabComponent = ({
	tabs,
	activeTabIndex,
	setActiveTabIndex,
}: CustomTabProps) => {
	const [value, setValue] = useState<string>(
		tabs[activeTabIndex ?? 0].tabValue
	);

	const handleChange = (_: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
		if (setActiveTabIndex)
			setActiveTabIndex(tabs.findIndex((x) => x.tabValue === newValue));
	};

	useEffect(() => {
		setValue(tabs[activeTabIndex ?? 0].tabValue);
	}, [activeTabIndex]);

	const theme = useTheme();

	return (
		<TabContext value={value}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<TabList
					onChange={handleChange}
					sx={{ px: 0 }}
					TabIndicatorProps={{
						sx: {
							backgroundColor: tabs.find((x) => x.tabValue === value)?.error
								? theme.colors.error.main
								: theme.colors.primary.main,
						},
					}}
				>
					{tabs &&
						tabs.map((x) => {
							const error = x.error ?? false;
							const disabled = x.disabled ?? false;

							const color = error
								? theme.colors.error.main
								: disabled
								? theme.colors.alpha.grey[2]
								: theme.colors.primary.main;
							const backgroundColor = disabled
								? theme.colors.alpha.grey[1]
								: theme.colors.alpha.trueWhite;
							return (
								<Tab
									label={x.tabLabel}
									value={x.tabValue}
									key={`tab-${x.tabValue}`}
									disabled={x.disabled ?? false}
									sx={{
										'&.MuiTab-root': {
											color,
											backgroundColor,
										},
										'&.MuiTab-root:hover': {
											color,
										},
									}}
								/>
							);
						})}
				</TabList>
			</Box>
			{tabs &&
				tabs.map((x) => (
					<TabPanel
						value={x.tabValue}
						key={`tab-panel-${x.tabValue}`}
						sx={{ px: 0 }}
					>
						{x.tabContent}
					</TabPanel>
				))}
		</TabContext>
	);
};
