import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthStorage } from 'src/services/firebase/setAuthStorage';
import { UserRoles } from 'src/services/types/user.types';
import { IUserSlice } from 'src/store/user/userSlice.contracts';
import { logout } from '..';

export const persistAuth = createAsyncThunk(
	'user/persistAuth',
	async (params: { userAuth: Partial<IUserSlice> }, { rejectWithValue }) => {
		try {
			if (!params.userAuth.name) {
				let user: IUserSlice = {
					name: null,
					firebaseUser: null,
					status: 'idle',
					userType: null,
					error: null,
					accessToken: null,
					refreshToken: null,
					...params.userAuth,
				};

				const userStorage = getAuthStorage();

				if (userStorage) {
					const userSlice: IUserSlice = JSON.parse(userStorage);
					user = {
						...user,
						name: params.userAuth.name ?? userSlice?.name,
						userType: userSlice?.userType ?? UserRoles.User,
						accessToken:
							(await params.userAuth.firebaseUser?.getIdToken()) ??
							userSlice.accessToken,
						refreshToken:
							params.userAuth.firebaseUser?.refreshToken ??
							userSlice.refreshToken,
						status: 'authenticated',
					};
				}

				return user;
			}

			return params.userAuth as IUserSlice;
		} catch (error: any) {
			return rejectWithValue(error.message);
		}
	}
);
