import { IconProps } from 'features';
import pages from 'src/router/routes';

export type SidebarTitleProps = {
	title: string;
	icon: IconProps['icon'];
	path: string;
	children?: SidebarTitleProps[];
	open?: boolean;
	caslItem?: string;
};

export const SidebarTitles = (adminUser: boolean): SidebarTitleProps[] => {
	const titles: SidebarTitleProps[] = [
		{
			title: 'All Farms',
			caslItem: 'farms',
			icon: 'UilForward',
			open: false,
			path: pages.farms.allFarms.path,
		},
		{
			title: 'Crop Management',
			caslItem: 'cropManagement',
			icon: 'UilTrees',
			open: false,
			path: pages.cropManagement.root,
		},
		{
			title: 'Library',
			caslItem: 'library',
			icon: 'UilBooks',
			open: false,
			path: pages.library.library.path,
		},
	];

	if (adminUser) {
		titles.push(
			{
				title: 'Help Requests',
				caslItem: 'helpRequests',
				icon: 'UilQuestionCircle',
				open: false,
				path: '',
				children: [
					{
						title: 'TMS Help Requests',
						icon: 'UilQuestionCircle',
						path: pages.helpRequests.helpRequestsTMS.path,
					},
					{
						title: 'CA Help Requests',
						icon: 'UilQuestionCircle',
						path: pages.helpRequests.helpRequestsCA.path,
					},
					{
						title: '4U Help Requests',
						icon: 'UilQuestionCircle',
						path: pages.helpRequests.helpRequests4U.path,
					},
				],
			},
			{
				title: 'Users',
				caslItem: 'users',
				icon: 'UilUsersAlt',
				open: false,
				path: '',
				children: [
					{
						title: 'TMS Users',
						icon: 'UilUsersAlt',
						path: pages.users.tmsUsers.path,
					},
					{
						title: 'CA Users',
						icon: 'UilShield',
						path: pages.users.cropAdvisors.path,
					},
					{
						title: '4U Users',
						icon: 'UilUsersAlt',
						path: pages.users.bcCandidates.path,
					},
				],
			});
	}

	return titles;
};
