const pages = {
	login: {
		name: 'login',
		path: '/login',
	},
	forgotPassword: {
		name: 'forgot-password',
		path: '/forgot-password',
	},
	setNewPassword: {
		name: 'set-new-password',
		path: '/set-new-password',
	},
	farms: {
		root: 'farms',
		allFarms: {
			name: 'all-farms',
			path: '/farms/',
		},
		farmOverview: {
			name: 'farm-overview',
			path: '/farms/overview',
		},
		farmBlocks: {
			name: 'blocks',
			path: '/farms/overview/blocks',
		},
	},
	users: {
		root: 'users',
		tmsUsers: {
			name: 'tms-users',
			path: '/users/tms-users',
		},
		cropAdvisors: {
			name: 'ca-users',
			path: '/users/ca-users',
		},
		bcCandidates: {
			name: '4u-users',
			path: '/users/4u-users',
		},
	},
	library: {
		root: 'library',
		library: {
			name: 'library',
			path: '/library',
		},
		cropProtection: {
			name: 'crop-protection',
			path: '/library/crop-protection',
		},
		plantNutrition: {
			name: 'plant-nutrition',
			path: '/library/plant-nutrition',
		},
		seeds: {
			name: 'seeds',
			path: '/library/seeds',
		},
		document: {
			name: 'view-document',
			path: '/library/document',
		},
	},
	cropManagement: {
		root: 'crop-management',
		path: '/crop-management',
		cropClass: {
			name: 'crops',
			path: '/crop-management/:crop-class',
		},
		cropSubclass: {
			name: 'subcrops',
			path: '/crop-management/:crop-class/:crop-subclass',
		},
		cultivars: {
			name: 'cultivars',
			path: '/crop-management/:crop-class/:crop-subclass/:crop/cultivars',
		},
		crops: {
			name: 'crops',
			path: '/crop-management/:crop-class/:crop-subclass/:crops',
		},
	},
	helpRequests: {
		root: 'help-requests',
		helpRequestsTMS: {
			name: 'tms-help-requests',
			path: '/help-requests/tms-help-requests',
		},
		helpRequests4U: {
			name: '4u-help-requests',
			path: '/help-requests/4u-help-requests',
		},
		helpRequestsCA: {
			name: 'ca-help-requests',
			path: '/help-requests/ca-help-requests',
		},
	},
	profile: {
		name: 'user-profile',
		path: '/profile',
	},
	systemStatus: {
		name: 'system-status',
		path: '/status/system',
	},
	status: {
		root: 'status',
		statusComingSoon: {
			name: 'coming-soon',
			path: '/status/coming-soon',
		},
		statusMaintenance: {
			name: 'maintenance',
			path: '/status/maintenance',
		},
		status404: {
			name: '404',
			path: '/status/404',
		},
		status500: {
			name: '500',
			path: '/status/500',
		},
		systemOverview: {
			name: 'system-overview',
			path: 'status/system-overview',
		},
	},
};

export default pages;
