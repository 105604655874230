import { useState } from 'react';
import { AppBar, Box, useTheme } from '@mui/material';
import { UserContainer } from './UserContainer';
import { HeaderWrapper, StyledDivider } from './Header.styles';
import { Breadcrumbs, Button, Icon } from 'features';
import { useGetCropCultivarQuery } from 'src/services/endpoints/cropsApi';
import { QuestionModal } from 'src/components/organisms/QuestionModal/QuestionModal';


const Header = () => {
	const [questionModal, setQuestionModal] = useState<boolean>(false);

	const theme = useTheme();
	const drawerWidth = theme.sidebar.width;

	const handleQuestionModal = () => {
		setQuestionModal(true);
	};

	const handleClose = () => {
		setQuestionModal(false);
	};

	useGetCropCultivarQuery();

	return (
		<>
			<QuestionModal
				closeModal={() => setQuestionModal(false)}
				modalState={questionModal}
				openModal={() => setQuestionModal(true)}
			/>
			<AppBar
				position="fixed"
				color="default"
				elevation={0}
				sx={{ width: `calc(100% - ${drawerWidth})`, ml: `${drawerWidth}` }}
			>
				<HeaderWrapper>
					<Breadcrumbs separator={<Icon icon="UilAngleRight" />} />
					<Box display={'flex'} alignItems={'center'} gap={'10px'}>
						<Button
							variant="contained"
							color="primary"
							endIcon={'UilQuestionCircle'}
							size="small"
							onClick={handleQuestionModal}
						>
							Ask a question
						</Button>
						<StyledDivider orientation="vertical" />
						<UserContainer
							settings={[
								{
									key: '1',
									children: 'Edit Profile',
									icon: 'UilPen',
								},
								{
									key: '2',
									children: 'Logout',
									icon: 'UilSignout',
								},
							]}
						/>
					</Box>
				</HeaderWrapper>
			</AppBar>
		</>
	);
};

export default Header;
