import { alpha, Box, Divider, lighten, styled } from '@mui/material';

export const DrawerWrapper = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: ' 30px 25px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

export const ListDropdownBar = styled(Divider)(({ theme }) => ({
  width: '2px',
  backgroundColor: theme.colors.alpha.white[10],
  borderRadius: '20px',
  marginLeft: '12px',
  marginRight: '15px',
  marginBottom: '-4px'
}));

export const ScreenWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  height: '100%',
  '.MuiPageTitle-wrapper': {
    background:
      theme.palette.mode === 'dark'
        ? theme.colors.alpha.trueWhite[5]
        : theme.colors.alpha.white[50],
    marginBottom: `${theme.spacing(4)}`,
    boxShadow:
      theme.palette.mode === 'dark'
        ? `0 1px 0 ${alpha(
            lighten(theme.colors.primary.main, 0.7),
            0.15
          )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
        : `0px 2px 4px -3px ${alpha(
            theme.colors.alpha.black[100],
            0.1
          )}, 0px 5px 12px -4px ${alpha(theme.colors.alpha.black[100], 0.05)}`
  }
}));
