import {
	Avatar,
	Box,
	Button,
	DialogActions,
	DialogTitle,
	Divider,
	Typography,
	styled,
	Dialog,
	DialogContent,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { Icon } from 'features/src/atoms/Icon/Icon';
import { IconButton } from 'features/src/atoms/IconButton/IconButton';
import { IUserSlice } from 'src/store/user/userSlice.contracts';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { updateProfile } from 'firebase/auth';
import { LoadingButton } from 'features';
import { toast } from 'react-toastify';
import { useAuth } from 'src/features/authentication';
import { storage } from 'src/services/firebase/firebase';

export const PopoverWrapper = styled(Box)(({ theme }) => ({
	width: '500px',
	padding: '0px',
	display: 'flex',
	flexDirection: 'column',
}));

interface ProfileModalProps {
	handleClose: () => void;
	user: IUserSlice;
	open: boolean;
}

export const ProfileModal = ({
	handleClose,
	user,
	open,
}: ProfileModalProps) => {
	const [avatarImage, setAvatarImage] = useState<string>(user.firebaseUser?.photoURL ?? '');
	const [file, setFile] = useState<File | null>(null);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleCancel = () => {
		handleClose();
		setAvatarImage(user.firebaseUser?.photoURL ?? '');
		setFile(null);
	};

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const selectedFile = e.target.files?.[0];
		if (selectedFile) {
			setFile(selectedFile);
			const imageUrl = URL.createObjectURL(selectedFile);
			setAvatarImage(imageUrl);
		}
	};

	const { user: currentUser } = useAuth();

	const handleSave = async () => {
		setIsSubmitting(true);
		try {
			if (file) {
				const storageRef = ref(
					storage,
					`Users/${user.firebaseUser?.uid}/ProfilePic.png`
				);
				// upload image to firebase storage:
				await uploadBytes(storageRef, file);
				const url = await getDownloadURL(storageRef);
				if (currentUser) {
					// update profileURL on firebase user:
					await updateProfile(currentUser, { photoURL: url });
				}
			}
			toast.success('Profile saved');
		} catch (error: any) {
			toast.error(error);
		} finally {
			setIsSubmitting(false);
			handleClose();
		}
	};

	return (
		<Dialog onClose={handleCancel} open={open}>
			<PopoverWrapper>
				<DialogTitle
					display='flex'
					flexDirection='row'
					alignItems='center'
					gap='12px'
					sx={{
						justifyContent: 'space-between',
						padding: '26px 26px 14px 26px',
					}}>
					<Box
						display='flex'
						flexDirection='row'
						alignItems='center'
						gap='12px'>
						<Icon icon='UilEditAlt' />
						<Typography fontWeight={700} fontSize='24px'>
							Edit Profile
						</Typography>
					</Box>
					<IconButton
						icon='UilMultiply'
						onClick={handleCancel}
						sx={{ alignSelf: 'flex-end' }}
					/>
				</DialogTitle>

				<Divider
					sx={{ height: '2px', marginRight: '26px', marginLeft: '26px' }}
				/>
				<DialogContent
					sx={{
						padding: '20px 26px 20px 26px',
					}}>
					{/* MY PROFILE */}
					<Box
						display='flex'
						flexDirection='row'
						alignItems='center'
						justifyContent='space-between'
						gap={1}>
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Typography fontWeight={700} fontSize='20px' paddingBottom='20px'>
								My Profile
							</Typography>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: '6px',
									paddingBottom: '20px',
								}}>
								<Typography fontWeight={700} fontSize='14px'>
									Name:
								</Typography>
								<Typography fontWeight={400} fontSize='16px'>
									{user.name}
								</Typography>
							</Box>
							<Box
								sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
								<Typography fontWeight={700} fontSize='14px'>
									Email Address:
								</Typography>
								<Typography fontWeight={400} fontSize='16px'>
									{user.firebaseUser?.email}
								</Typography>
							</Box>
						</Box>
						<Box
							sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
							<Avatar
								sx={{ width: '130px', height: '130px', right: -40 }}
								alt='Avatar'
								src={avatarImage ?? user.firebaseUser?.photoURL}
							/>
							<input
								accept='image/*'
								style={{ display: 'none' }}
								id='avatar-input'
								type='file'
								onChange={handleFileChange}
							/>
							<IconButton
								color='inherit'
								icon='UilEditAlt'
								onClick={() => document.getElementById('avatar-input')?.click()}
								sx={{
									bottom: -10,
									right: 0,
									position: 'relative',
									bgcolor: (theme) => theme.palette.primary.main,
									color: 'white',
									'&:hover': {
										color: 'white',
										bgcolor: (theme) => theme.palette.primary.dark,
									},
								}}
							/>
						</Box>
					</Box>
				</DialogContent>
				<Divider
					sx={{ height: '2px', marginRight: '26px', marginLeft: '26px', mt: 2, mb: 3 }}
				/>
				
				{/* ACTION BUTTONS */}
				<DialogActions
					sx={{ justifyContent: 'center', padding: '0px 60px 26px 60px' }}>
					<Button
						variant='outlined'
						color='secondary'
						fullWidth
						onClick={handleCancel}>
						Cancel
					</Button>
					<LoadingButton
						loading={isSubmitting}
						variant='contained'
						endIcon='UilSave'
						fullWidth
						onClick={handleSave}>
						Save Profile
					</LoadingButton>
				</DialogActions>
			</PopoverWrapper>
		</Dialog>
	);
};
