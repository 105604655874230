import { pnApi } from '../pnBaseApi';
import {
	RestApiResponse,
	RestApiResponseWithData,
	RestApiResponseWithPagination,
} from '../types/apiResponse.types';
import {
	CreateCrop,
	CreateCropClass,
	CreateCropSubClass,
	CreateCultivar,
	Crop,
	CropClass,
	CropSubClass,
	Cultivar,
	UpdateCrop,
	UpdateCropClass,
	UpdateCropSubClass,
	UpdateCultivar,
	CropCultivar,
	TimeOfApplication,
} from '../types/crops.types';
import { CropQueryParams, SearchQueryParams } from '../types/queryParams.types';

const cropsApi = pnApi.injectEndpoints({
	endpoints: (builder) => ({
		// ##################### Class ##################### \\
		getAllCropClasses: builder.query<
			RestApiResponseWithPagination<CropClass[]>,
			SearchQueryParams
		>({
			query: (args: SearchQueryParams) => {
				return {
					url: 'CropClass',
					params: args,
				};
			},
			providesTags: ['CropClasses'],
		}),

		getCropClass: builder.query<
			RestApiResponseWithPagination<CropClass>,
			string
		>({
			query: (id) => {
				return {
					url: `CropClass/${id}`,
				};
			},
			providesTags: ['CropClass'],
		}),

		createCropClass: builder.mutation<
			RestApiResponseWithData<CropClass>,
			CreateCropClass
		>({
			query: (body) => {
				return {
					url: 'CropClass',
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['CropClasses'],
		}),

		updateCropClass: builder.mutation<
			RestApiResponseWithData<CropClass>,
			UpdateCropClass
		>({
			query: (body) => {
				return {
					url: `CropClass/${body.id}`,
					method: 'PATCH',
					body,
				};
			},
			invalidatesTags: ['CropClasses', 'CropSubClasses'],
		}),

		deleteCropClass: builder.mutation<RestApiResponse, string>({
			query: (id) => {
				return {
					url: `CropClass/${id}`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['CropClasses'],
		}),

		// ##################### SubClass ##################### \\
		getAllCropSubClasses: builder.query<
			RestApiResponseWithPagination<CropSubClass[]>,
			CropQueryParams
		>({
			query: (args) => {
				return {
					url: 'CropSubclass',
					params: args,
				};
			},
			providesTags: ['CropSubClasses'],
		}),

		getCropSubClass: builder.query<
			RestApiResponseWithPagination<CropSubClass>,
			string
		>({
			query: (id) => {
				return {
					url: `CropSubclass/${id}`,
				};
			},
			providesTags: ['CropClass'],
		}),

		createCropSubClass: builder.mutation<
			RestApiResponseWithData<CropSubClass>,
			CreateCropSubClass
		>({
			query: (body) => {
				return {
					url: 'CropSubclass',
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['CropSubClasses'],
		}),

		updateCropSubClass: builder.mutation<
			RestApiResponseWithData<CropSubClass>,
			UpdateCropSubClass
		>({
			query: (body) => {
				return {
					url: `CropSubclass/${body.id}`,
					method: 'PATCH',
					body,
				};
			},
			invalidatesTags: ['CropSubClasses', 'Crops'],
		}),

		deleteCropSubclass: builder.mutation<RestApiResponse, string>({
			query: (id) => {
				return {
					url: `CropSubclass/${id}`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['CropSubClasses'],
		}),

		// ##################### Crops ##################### \\
		getAllCrops: builder.query<
			RestApiResponseWithPagination<Crop[]>,
			CropQueryParams
		>({
			query: (args) => {
				return {
					url: 'Crop',
					params: args,
				};
			},
			providesTags: ['Crops'],
		}),

		getAllCropsWithoutPaginatio: builder.query<
			RestApiResponseWithData<Crop[]>,
			{}
		>({
			query: (args) => {
				return {
					url: 'Crop/All',
					params: args,
				};
			},
			providesTags: ['Crops'],
		}),

		getCrop: builder.query<RestApiResponseWithPagination<Crop>, string>({
			query: (id) => {
				return {
					url: `Crop/${id}`,
				};
			},
			providesTags: ['Crop'],
		}),

		createCrop: builder.mutation<RestApiResponseWithData<Crop>, CreateCrop>({
			query: (body) => {
				return {
					url: 'Crop',
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['Crops', 'CropCultivar'],
		}),

		updateCrop: builder.mutation<RestApiResponseWithData<Crop>, UpdateCrop>({
			query: (body) => {
				return {
					url: `Crop/${body.id}`,
					method: 'PATCH',
					body,
				};
			},
			invalidatesTags: ['Crops', 'Cultivars', 'CropCultivar'],
		}),

		deleteCrop: builder.mutation<RestApiResponse, string>({
			query: (id) => {
				return {
					url: `Crop/${id}`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['Crops'],
		}),

		// ##################### Cultivars ##################### \\
		getAllCultivar: builder.query<
			RestApiResponseWithPagination<Cultivar[]>,
			CropQueryParams
		>({
			query: (args) => {
				return {
					url: 'Cultivar',
					params: args,
				};
			},
			providesTags: ['Cultivars'],
		}),

		getCultivar: builder.query<RestApiResponseWithPagination<Cultivar>, string>(
			{
				query: (id) => {
					return {
						url: `Cultivar/${id}`,
					};
				},
				providesTags: ['Cultivar'],
			}
		),

		createCultivar: builder.mutation<
			RestApiResponseWithData<Cultivar>,
			CreateCultivar
		>({
			query: (body) => {
				return {
					url: 'Cultivar',
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['Cultivars', 'CropCultivar'],
		}),

		updateCultivar: builder.mutation<
			RestApiResponseWithData<Cultivar>,
			UpdateCultivar
		>({
			query: (body) => {
				return {
					url: `Cultivar/${body.id}`,
					method: 'PATCH',
					body,
				};
			},
			invalidatesTags: ['Cultivars', 'CropCultivar'],
		}),

		deleteCultivar: builder.mutation<RestApiResponse, string>({
			query: (id) => {
				return {
					url: `Cultivar/${id}`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['Cultivars'],
		}),

		// ##################### CropCultivars ##################### \\
		getCropCultivar: builder.query<
			RestApiResponseWithData<CropCultivar[]>,
			void
		>({
			query: () => {
				return {
					url: 'Crop/all-including-cultivars',
				};
			},
			providesTags: ['CropCultivar'],
		}),

		// ################### TimeOfApplication ################### \\
		getCropTimesOfApplication: builder.query<
			RestApiResponseWithData<TimeOfApplication[]>,
			string
		>({
			query: (id) => {
				return {
					url: `Crop/${id}/TimesOfApplication`,
				};
			},
			providesTags: (result) =>
				result?.data?.at(0)
					? [{ type: 'TimesOfApplication', id: result?.data?.at(0)?.cropId }]
					: ['TimesOfApplication'],
		}),

		updateCropTimeOfApplication: builder.mutation<
			RestApiResponse,
			{ cropId: string; timesOfApplication: TimeOfApplication[] }
		>({
			query: (body) => {
				return {
					url: 'Crop/TimesOfApplication',
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['Cultivars', 'CropCultivar', 'TimesOfApplication'],
		}),
	}),
});

export const {
	useGetAllCropClassesQuery,
	useGetCropClassQuery,
	useUpdateCropClassMutation,
	useCreateCropClassMutation,
	useCreateCropMutation,
	useCreateCropSubClassMutation,
	useCreateCultivarMutation,
	useGetAllCropSubClassesQuery,
	useGetAllCropsQuery,
	useGetAllCultivarQuery,
	useGetCropQuery,
	useGetCultivarQuery,
	useGetCropSubClassQuery,
	useGetCurrentUserQuery,
	useUpdateCropMutation,
	useUpdateCropSubClassMutation,
	useUpdateCultivarMutation,
	useLazyGetCropClassQuery,
	useLazyGetCropSubClassQuery,
	useLazyGetCropQuery,
	useLazyGetAllCropsQuery,
	useLazyGetCultivarQuery,
	useGetCropCultivarQuery,
	useLazyGetCropCultivarQuery,
	useDeleteCropClassMutation,
	useDeleteCropMutation,
	useDeleteCropSubclassMutation,
	useDeleteCultivarMutation,
	useGetCropTimesOfApplicationQuery,
	useUpdateCropTimeOfApplicationMutation,
	useGetAllCropsWithoutPaginatioQuery,
} = cropsApi;
