import { Box, Divider } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { IconButton } from 'features';

export const HeaderWrapper = styled(Box)(
  ({ theme }) => `
          height: ${theme.header.height};
          color: ${theme.header.textColor};
          padding: ${theme.spacing(0, 2)};
          right: 0;
          top: 0;
          background-color: ${alpha(
            theme.header.background ?? '#111633',
            0.95
          )};
          justify-content: space-between;
          @media (min-width: ${theme.breakpoints.values.lg}px) {
              left: ${theme.sidebar.width};
              width: auto;
          }
          display: flex;
          align-items: center;
  `
);

export const StyledIconButton = styled(IconButton)(
  ({ theme }) => `        
       background-color: transparent;
       &:hover {
              background-color: transparent;
              color: ${theme.palette.primary.main};
         }
    `
);

export const StyledUserIconButton = styled(IconButton)(
  ({ theme }) => `
        background-color: transparent;
        color: ${theme.colors.alpha.grey[3]};
        gap: ${theme.spacing(0.6)};
        &:hover {
            background-color: transparent;
        }
    `
);

export const StyledDivider = styled(Divider)(
  ({ theme }) => `
    height: 25px;
    border-radius: 10px;
    width: 2px;
`
);
