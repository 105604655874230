import { IUserSlice } from 'src/store/user/userSlice.contracts';

const setAuthStorage = (userSlice: IUserSlice) => {
	localStorage.setItem(
		process.env.REACT_APP_APP_NAME ?? 'Intelekt-TMS',
		JSON.stringify({
			status: userSlice.status,
			accessToken: userSlice.accessToken,
			refreshToken: userSlice.refreshToken,
			userType: userSlice.userType,
			name: userSlice.name,
			error: userSlice.error,
		})
	);
};

const getAuthStorage = () => {
	return localStorage.getItem(process.env.REACT_APP_APP_NAME ?? 'Intelekt-TMS');
};

const removeAuthStorage = () => {
	return localStorage.removeItem(process.env.REACT_APP_APP_NAME ?? 'Intelekt-TMS');
};

export { setAuthStorage, getAuthStorage, removeAuthStorage };
