import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { DocumentCropSelect } from './DocumentCropSelect';
import { DocumentProductsSelect } from './DocumentProductsSelect';
import { DocumentTypeSelect } from './DocumentTypeSelect';
import { CropsQueryType } from '../../../types/crops.types';
import { LibraryDataFilter } from '../../../types/libraryData.types';
import { ProductsQueryType } from '../../../types/product.types';

export const LibraryDataFilters = ({
	setFilterData,
	tagsQuery,
	cropsQuery,
	filterData,
}: {
	setFilterData: (data: Partial<LibraryDataFilter>) => void;
	filterData: LibraryDataFilter;
	tagsQuery: ProductsQueryType;
	cropsQuery: CropsQueryType;
}) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
			<DocumentTypeSelect
				onChange={(value) =>
					setFilterData({
						documentType: value,
					})
				}
				width={isSmallScreen ? '100%' : '20%'}
				value={filterData.documentType}
			/>

			<DocumentProductsSelect
				onChange={(productId) =>
					setFilterData({
						tag: productId,
					})
				}
				width={isSmallScreen ? '100%' : '20%'}
				tagQuery={tagsQuery}
				value={filterData.tag}
			/>

			<DocumentCropSelect
				onChange={(value) =>
					setFilterData({
						cropId: (value as string) ?? '',
					})
				}
				width={isSmallScreen ? '100%' : '20%'}
				cropQuery={cropsQuery}
				value={filterData.cropId}
			/>
		</Stack>
	);
};
