import {
	ListResult,
	StorageReference,
	getDownloadURL,
	getStorage,
	listAll,
	ref,
} from 'firebase/storage';
import { toast } from 'react-toastify';
import { getRemoteConfig } from 'firebase/remote-config';
import { initializeApp } from 'firebase/app';

export const app = initializeApp({
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

const storage = getStorage(app);
const remoteConfig = getRemoteConfig();

//For Development mode, 10min
remoteConfig.settings.minimumFetchIntervalMillis = 600000;

const listAllFromStorage = async (
	path: string
): Promise<ListResult | undefined> => {
	try {
		const storageRef = ref(storage, path);
		return await listAll(storageRef);
	} catch (e: any) {
		toast.error(e.code);
		throw new Error(e.code);
	}
};

const getDownloadUrl = async (
	ref: StorageReference
): Promise<string | undefined> => {
	try {
		return await getDownloadURL(ref);
	} catch (e: any) {
		toast.error(e.code);
		throw new Error(e.code);
	}
};

export { storage, remoteConfig, listAllFromStorage, getDownloadUrl };
