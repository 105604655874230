import { Suspense, lazy, FC } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import { SuspenseLoader } from 'features';
import pages from './routes';
import BaseLayout from 'src/components/templates/BaseLayout/index';
import AuthGuard from './components/AuthGaurd';
import SystemOverviewPage from 'src/components/pages/System/index';

const Loader = (Component: FC) => (props: any) =>
	(
		<Suspense fallback={<SuspenseLoader />}>
			<Component {...props} />
		</Suspense>
	);

// Farm Pages
const AllFarms = Loader(lazy(() => import('src/components/pages/Farms')));
const FarmOverview = Loader(
	lazy(() => import('src/components/pages/FarmOverview'))
);
const FarmBlock = Loader(
	lazy(() => import('src/components/pages/FarmOverview/FarmBlock'))
);

// User Pages
const TmsUsers = Loader(lazy(() => import('src/components/pages/TmsUsers')));
const BcCandidates = Loader(
	lazy(() => import('src/components/pages/BcCandidates'))
);

const Profile = Loader(lazy(() => import('src/components/pages/Profile')));

// Advisor Pages
const CropAdvisors = Loader(
	lazy(() => import('src/components/pages/CropAdvisors'))
);

// Crop management
const CropClass = Loader(lazy(() => import('src/components/pages/Crops')));
const CropSubclass = Loader(
	lazy(() => import('src/components/pages/Crops/SubClass'))
);
const Crop = Loader(lazy(() => import('src/components/pages/Crops/Crop')));
const Cultivars = Loader(
	lazy(() => import('src/components/pages/Crops/Cultivars'))
);

// Library
const LibraryDataScreen = Loader(
	lazy(() => import('src/components/pages/Library/LibraryDataScreen'))
);
const CropProtectionScreen = Loader(
	lazy(() => import('src/components/pages/Library/CropProtectionScreen'))
);
const PlantNutritionScreen = Loader(
	lazy(() => import('src/components/pages/Library/PlantNutritionScreen'))
);
const SeedsScreen = Loader(
	lazy(() => import('src/components/pages/Library/SeedsScreen'))
);
const ViewDocument = Loader(
	lazy(() => import('features/src/organisms/Library/ViewDocument'))
);

// Help Requests
const HelpRequestsTMS = Loader(
	lazy(() => import('src/components/pages/HelpRequests/HelpRequestsTMS'))
);

const HelpRequestsCA = Loader(
	lazy(() => import('src/components/pages/HelpRequests/HelpRequestsCA'))
);

const HelpRequests4U = Loader(
	lazy(() => import('src/components/pages/HelpRequests/HelpRequests4U'))
);

// Status
const Status404 = Loader(
	lazy(() => import('src/components/Fallbacks/Status/Status404'))
);
const Status500 = Loader(
	lazy(() => import('src/components/Fallbacks/Status/Status500'))
);
const StatusComingSoon = Loader(
	lazy(() => import('src/components/Fallbacks/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
	lazy(() => import('src/components/Fallbacks/Status/Maintenance'))
);
const SystemStatus = Loader(
	lazy(() => import('src/components/pages/System/index'))
);

const routes: RouteObject[] = [
	{
		path: pages.systemStatus.path,
		element: (
			<AuthGuard>
				<SystemStatus />
			</AuthGuard>
		),
	},
	{
		/**
		 * This is the root path API_URL
		 */
		path: '',
		/**
		 * This is a basic container element without any Navbars or Sidebars
		 */
		element: (
			<AuthGuard>
				<BaseLayout />
			</AuthGuard>
		),
		children: [
			{
				path: '/',
				element: <Navigate to={pages.farms.allFarms.path} replace />,
			},
			{
				path: pages.farms.allFarms.path,
				element: <AllFarms />,
			},
			{
				path: pages.farms.root,
				children: [
					{
						path: pages.farms.farmOverview.path,
						element: <FarmOverview />,
					},
					{
						path: pages.farms.farmBlocks.path,
						element: <FarmBlock />,
					},
				],
			},
			{
				path: pages.cropManagement.root,
				children: [
					{
						path: pages.cropManagement.path,
						element: <CropClass />,
					},
					{
						path: pages.cropManagement.cropClass.path,
						element: <Navigate to={pages.cropManagement.path} />,
					},
					{
						path: pages.cropManagement.cropSubclass.path,
						element: <CropSubclass />,
					},
					{
						path: pages.cropManagement.crops.path,
						element: <Crop />,
					},
					{
						path: pages.cropManagement.cultivars.path,
						element: <Cultivars />,
					},
				],
			},
			{
				path: pages.helpRequests.root,
				children: [
					{
						path: pages.helpRequests.helpRequestsTMS.path,
						element: <HelpRequestsTMS />,
					},
					{
						path: pages.helpRequests.helpRequestsCA.path,
						element: <HelpRequestsCA />,
					},
					{
						path: pages.helpRequests.helpRequests4U.path,
						element: <HelpRequests4U />,
					},
				],
			},
			{
				path: pages.users.root,
				children: [
					{
						path: pages.users.tmsUsers.path,
						element: <TmsUsers />,
					},
					{
						path: pages.users.cropAdvisors.path,
						element: <CropAdvisors />,
					},
					{
						path: pages.users.bcCandidates.path,
						element: <BcCandidates />,
					},
				],
			},
			{
				path: pages.profile.path,
				element: <Profile />,
			},
			{
				path: pages.library.root,
				children: [
					{
						path: pages.library.library.path,
						element: <LibraryDataScreen />,
					},
					{
						path: pages.library.cropProtection.path,
						element: <CropProtectionScreen />,
					},
					{
						path: pages.library.plantNutrition.path,
						element: <PlantNutritionScreen />,
					},
					{
						path: pages.library.seeds.path,
						element: <SeedsScreen />,
					},
					{
						path: pages.library.document.path,
						element: <ViewDocument />,
					},
				],
			},
			{
				path: pages.status.root,
				children: [
					{
						path: '',
						element: <Navigate to="404" replace />,
					},
					{
						path: pages.status.status404.name,
						element: <Status404 />,
					},
					{
						path: pages.status.status500.name,
						element: <Status500 />,
					},
					{
						path: pages.status.statusMaintenance.name,
						element: <StatusMaintenance />,
					},
					{
						path: pages.status.statusComingSoon.name,
						element: <StatusComingSoon />,
					},
					{
						path: pages.status.systemOverview.name,
						element: <SystemOverviewPage />,
					},
				],
			},
			{
				path: '*',
				element: <Status404 />,
			},
		],
	},
];

export default routes;
