import { FC, useState, createContext, useEffect } from 'react';
import { SidebarTitleProps, SidebarTitles } from 'src/components/templates/Sidebar/SidebarTitles';

type SidebarContext = {
  sidebarToggle: any;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  titles: SidebarTitleProps[];
  openTitleCollapse: (title: string) => void;
  resetNavItems: () => void;
};

export const SidebarContext = createContext<SidebarContext>({} as SidebarContext);

interface ISidebarProvider {
  children: any;
  adminUser?: boolean;
}

export const SidebarProvider: FC<ISidebarProvider> = ({ children, adminUser }: any) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [titles, setTitles] = useState<SidebarTitleProps[]>([]);

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  const openTitleCollapse = (title: string) => {
    const updatedTitles = titles?.map((t => {
      if (t.title === title) {
        return {
          ...t, 
          open: !t.open,
        }
      }

      return t;
    }));
    
    setTitles(updatedTitles);
  }

  // Reset nav to original state
  const resetNavItems = () => setTitles(SidebarTitles(adminUser));

  useEffect(() => {
    setTitles(SidebarTitles(adminUser));
  }, [adminUser])

  return (
    <SidebarContext.Provider value={{ sidebarToggle, toggleSidebar, closeSidebar, titles, openTitleCollapse, resetNavItems }}>
      {children}
    </SidebarContext.Provider>
  );
};
