import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import { useRef } from 'react';
import { Button, IconButton, blobToFile } from 'features';
import SignatureCanvas from 'react-signature-canvas';

interface SignatureModalProps {
    open: boolean;
    toggleOpen: () => void;
    onSubmit: (file: File) => void;
}

export const SignatureModal = ({ open, toggleOpen, onSubmit }: SignatureModalProps) => {
    const theme = useTheme();
    const padRef = useRef<SignatureCanvas>(null);

    const handleClear = () => {
        padRef.current?.clear();
    };

    const handleCancel = () => {
        toggleOpen();
    };

    const handleSave = () => {
        const canvas = padRef.current?.getTrimmedCanvas();

        canvas?.toBlob((blob) => {
            if (blob) onSubmit(blobToFile(blob, 'Signature.png'));
        });
        toggleOpen();
    };

    return (
        <Dialog open={open}>
            <DialogContent>
                <Stack gap={3}>
                    <IconButton icon='UilRedo' sx={{ alignSelf: 'flex-end' }} onClick={handleClear} />
                    <Box
                        sx={{
                            mx: 4,
                            border: `1px solid ${theme.colors.alpha.grey[3]}`,
                            borderRadius: '30px',
                            overflow: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <SignatureCanvas
                            ref={padRef}
                            canvasProps={{ width: "365px", height: "232px" }}
                        />
                    </Box>
                    <Typography variant='body' textAlign='center'>
                        Add your Signature or Initials in box above
                    </Typography>
                    <DialogActions
                        sx={{ justifyContent: 'center' }}>
                        <Button
                            variant='outlined'
                            color='secondary'
                            fullWidth
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='contained'
                            endIcon='UilSave'
                            fullWidth
                            onClick={handleSave}>
                            Save
                        </Button>
                    </DialogActions>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};