import {
	Button,
	Grid,
	Paper,
	Stack,
	Typography,
	alpha,
	useTheme,
} from '@mui/material';
import { CustomTextInput, DataGrid, Icon, getReadableEnum } from 'features';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	LibraryData,
	LibraryDataFilter,
	LibraryDataQueryType,
} from '../../types/libraryData.types';
import { CropsQueryType } from '../../types/crops.types';
import { ProductsQueryType } from '../../types/product.types';
import { useDebounce } from 'use-debounce';
import { LibraryDataGridHeaders } from './helpers/headers';
import { LibraryDataFilters } from './components/LibraryDataFilters';
import { GridRowParams } from '@mui/x-data-grid';
import { create } from 'zustand';

export enum LibraryFolder {
	CropProtection = 'CropProtection',
	PlantNutrition = 'PlantNutrition',
	Seeds = 'Seeds',
}

export type LibraryProps = {
	pathsToListScreen: string;
	libraryDataQuery: LibraryDataQueryType;
	tagsQuery: ProductsQueryType;
	cropsQuery: CropsQueryType;
	showTableActions: boolean;
	folder?: LibraryFolder;
	onDeleteClick?: (params: GridRowParams) => void;
	onViewClick?: (params: GridRowParams) => void;
	onDownloadClick?: (params: GridRowParams) => void;
	onPermissionEdit?: (params: GridRowParams) => void;
	platform: 'TMS' | 'CA' | '4U';
};

export const CategorySelect = ({
	pathsToListScreen,
	libraryDataQuery,
	tagsQuery,
	cropsQuery,
	platform,
	folder,
	onDeleteClick,
	onDownloadClick,
	onViewClick,
	onPermissionEdit,
}: LibraryProps) => {
	const navigate = useNavigate();
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [searchValue, setSearchValue] = useState<string>();
	const [debouncedSearch] = useDebounce(searchValue, 500);

	const theme = useTheme();

	const [filterData, setFilterData] = useState<LibraryDataFilter>({
		cropId: '',
		tag: '',
		documentType: undefined,
	});

	const { data, isLoading, isFetching } = libraryDataQuery({
		search: debouncedSearch,
		skip: paginationModel.page * paginationModel.pageSize,
		take: paginationModel.pageSize,
		cropId: filterData.cropId,
		tag: filterData.tag,
		documentType: filterData.documentType,
		folder,
	});

	const libraryCategories = Object.values(
		LibraryFolder
	) as Array<LibraryFolder>;

	const anySearchOrFilter =
		searchValue ||
		filterData.cropId ||
		filterData.tag ||
		filterData.documentType !== undefined;

	const setPartialFilterData = (data: Partial<LibraryDataFilter>) =>
		setFilterData({ ...filterData, ...data });

	const getColor = (folderToCheck: LibraryFolder) => {
		return folder !== folderToCheck && folder !== undefined
			? alpha(theme.palette.primary.light, 0.2)
			: theme.palette.common.white;
	};

	return (
		<Stack rowGap={2}>
			<CustomTextInput
				label="Search Library"
				placeholder="Search by document type, name"
				InputProps={{
					startAdornment: <Icon icon="UilSearch" sx={{ mr: 1 }} />,
					notched: false,
				}}
				onChange={(e) => setSearchValue(e.target.value)}
			/>

			<LibraryDataFilters
				cropsQuery={cropsQuery}
				tagsQuery={tagsQuery}
				setFilterData={setPartialFilterData}
				filterData={filterData}
			/>

			{anySearchOrFilter || folder !== undefined ? (
				<Paper sx={{ p: 4 }}>
					<DataGrid
						columns={LibraryDataGridHeaders(
							platform,
							anySearchOrFilter ? true : folder === undefined,
							onDeleteClick,
							onDownloadClick,
							onPermissionEdit,
							onViewClick
						)}
						rows={isLoading || isFetching ? [] : data?.data ?? []}
						rowCount={data?.totalRows}
						getRowId={(row: LibraryData) => row.id ?? row.fileName}
						paginationModel={paginationModel}
						onPaginationModelChange={setPaginationModel}
						loading={isLoading || isFetching}
						paginationMode="server"
						getRowClassName={(params) => `row-${params.row.folder}`}
						sx={{
							'& div.row-Seeds': {
								backgroundColor: getColor(LibraryFolder.Seeds),
							},
							'& div.row-PlantNutrition': {
								backgroundColor: getColor(LibraryFolder.PlantNutrition),
							},
							'& div.row-CropProtection': {
								backgroundColor: getColor(LibraryFolder.CropProtection),
							},
						}}
					/>
				</Paper>
			) : (
				<Grid container spacing={2}>
					{libraryCategories.map((item, i) => {
						return (
							<Grid item sm={6} key={`library-item-${i}`}>
								<Paper sx={{ py: 2, px: 4 }}>
									<Stack
										justifyContent={'space-between'}
										direction={'row'}
										alignItems={'center'}
									>
										<Typography>
											{getReadableEnum(
												LibraryFolder,
												(item as LibraryFolder).toString()
											)}
										</Typography>
										<Button
											size="small"
											variant="contained"
											onClick={() =>
												navigate(
													`${pathsToListScreen}/${item
														.match(/[A-Z][a-z]+/g)
														?.join('-')
														.toLocaleLowerCase()}`
												)
											}
										>
											View
										</Button>
									</Stack>
								</Paper>
							</Grid>
						);
					})}
				</Grid>
			)}
		</Stack>
	);
};
