import { defineAbility } from '@casl/ability';

export default function defineAbilityFor(userType: string) {
	return defineAbility((can, cannot) => {
		if (userType === 'Admin') {
			//user
			can('read', 'users');
			can('update', 'users');
			can('create', 'users');
			can('delete', 'users');
		} else if (userType === 'User') {
			//user
			cannot('read', 'users');
			cannot('update', 'users');
			cannot('create', 'users');
			cannot('delete', 'users');
		} else {
			cannot('read', 'users');
			cannot('update', 'users');
			cannot('create', 'users');
			cannot('delete', 'users');
		}
	});
}

// -----------------------------------------------------------------------
//EXAMPLES (https://casl.js.org/v6/en/package/casl-react)

//YOU CAN WRAP JSX ELEMENTS WITH THIS AND IT WILL RENDER BASED ON THE ABILITIES AND THE CURRENT USER
//YOU CAN MOST PROBABLY ADD THE USER TYPE TO THE USER SLICE

// <Can I="create" a="user">
//     <>if the above is can, this will show<>
//     <>if the above is cannot, this will show<>
// </Can>

// -----------------------------------------------------------------------
// AS CODE

// import { AbilityContext, Can } from "../../context/canContext";
// const ability = useContext(AbilityContext)

// if(ability.can("delete", "user")){
//     console.log('run this code')
// }
