import { FC, ReactNode } from 'react';
import { Box, useTheme } from '@mui/material';
import { Sidebar } from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import { ScreenWrapper } from '../Sidebar/Sidebar.styles';
import { Outlet } from 'react-router-dom';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import { useAppSelector } from 'src/hooks/hooks';
import { selectUser } from 'src/store/user/userSlice';
import { UserRoles } from 'src/services/types/user.types';

interface SidebarLayoutProps {
	children?: ReactNode;
}

const BaseLayout: FC<SidebarLayoutProps> = ({ children }) => {
	const theme = useTheme();

	const user = useAppSelector(selectUser);

	return (
		<SidebarProvider adminUser={user.userType === UserRoles.Admin}>
			<ScreenWrapper>
				<Header />
				<Sidebar />
				<Box
					sx={{
						position: 'relative',
						zIndex: 5,
						flex: 1,
						pt: `${theme.header.height}`,
						ml: `${theme.sidebar.width}`,
					}}
					display='block'>
					{children || <Outlet />}
				</Box>
			</ScreenWrapper>
		</SidebarProvider>
	);
};

export default BaseLayout;
