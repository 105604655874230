import React, { useRef, useState } from 'react';
import {
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Avatar,
	FormControl,
	DialogActions,
	Slide,
	useTheme,
	Box,
	Tooltip,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
	IconButton,
	RadioGroup,
	Icon,
	Button,
	CustomTextInput,
	ImagePopover,
	LoadingButton,
	QuestionFormWrapper,
	DashedImageBox,
} from 'features';
import { toast } from 'react-toastify';
import { useCreateQuestionMutation } from 'src/services/endpoints/questionsApi';
import { QuestionTMSRequest } from 'src/services/types/question.types';
import { Platform } from 'src/services/types/user.types';

const validationSchema = Yup.object().shape({
	type: Yup.string().required('Please choose a question type'),
	image: Yup.string().optional(),
	subject: Yup.string().required('Subject is required'),
	message: Yup.string().required('Message is required'),
});

const questionTypes = [
	{ label: 'Technical Query', value: 'Technical' },
	{ label: 'Account Query', value: 'Account' },
	{ label: 'General Query', value: 'General' },
];

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

interface QuestionModalProps {
	modalState: boolean;
	closeModal: () => void;
	openModal: () => void;
	subject?: string;
}

interface FileObj {
	file: File;
	tempUrl: string;
}

export const QuestionModal = ({
	modalState,
	closeModal,
	subject,
}: QuestionModalProps) => {
	const theme = useTheme();
	const [createQuestion] = useCreateQuestionMutation();
	const [uploadedFiles, setUploadedFiles] = useState<FileObj[]>([]);
	const [fileLimit, setFileLimit] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const initialValue: QuestionTMSRequest = {
		type: '',
		subject: subject ?? '',
		message: '',
		hasImages: false,
		platform: Platform.TMS,
	};

	const hiddenFileInput = useRef<HTMLInputElement>(null);

	const handleFileUploadClick = () => {
		hiddenFileInput.current?.click();
	};

	const handleAddFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.prototype.slice.call(e.target.files);
		const uploaded = [...uploadedFiles];
		const maxCount = 3;
		let limitExceeded = false;

		files.some((file) => {
			// check that the file does not already exist:
			if (uploaded.findIndex((f) => f.file.name === file.name) === -1) {
				uploaded.push({
					file: file,
					tempUrl: URL.createObjectURL(file),
				});
				if (uploaded.length === maxCount) setFileLimit(true);
				if (uploaded.length > maxCount) {
					toast.warning(`You can only add a maximum of ${maxCount} images`);
					setFileLimit(false);
					limitExceeded = true;
					// return true to break out of the loop:
					return true;
				}
			} else {
				toast.warning('Cannot add duplicate file');
			}
		});
		if (!limitExceeded) {
			setUploadedFiles(uploaded);
		}
	};

	const clearSingleFile = (fileName: string) => {
		setUploadedFiles(
			[...uploadedFiles].filter((fileObj) => {
				if (fileObj.file.name === fileName) {
					URL.revokeObjectURL(fileObj.tempUrl);
				}
				return fileObj.file.name !== fileName;
			})
		);
		if (fileLimit) setFileLimit(false);
	};

	const clearAllFiles = () => {
		uploadedFiles.map((fileObj) => {
			URL.revokeObjectURL(fileObj.tempUrl);
		});
		setUploadedFiles([]);
		setFileLimit(false);
	};

	const submitQuestion = async (values: QuestionTMSRequest): Promise<void> => {
		setIsSubmitting(true);
		try {
			const hasImages = uploadedFiles.length > 0;

			await createQuestion({
				type: values.type,
				subject: values.subject,
				message: values.message,
				hasImages,
				files: hasImages ? uploadedFiles.map((fileObj) => fileObj.file) : [],
				platform: Platform.TMS,
			}).unwrap();

			toast.success('Help Request has been sent');
			closeModal();
		} catch (error: any) {
			toast.error(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<>
			<Dialog
				maxWidth='lg'
				open={modalState}
				TransitionComponent={Transition}
				keepMounted
				scroll='body'
				onClose={closeModal}>
				<Formik
					validateOnMount
					initialValues={initialValue}
					onSubmit={async (values, { setValues, resetForm }) => {
						await submitQuestion(values);
						setValues({ ...initialValue });
						clearAllFiles();
						resetForm();
					}}
					validationSchema={validationSchema}
					enableReinitialize>
					{({ errors, touched, setValues, resetForm, values }) => {
						const handleCloseModal = () => {
							closeModal();
							setValues({ ...initialValue });
							clearAllFiles();
							resetForm();
						};
						return (
							<Form>
								<QuestionFormWrapper>
									<DialogActions
										sx={{
											p: '40px 40px 0px 40px',
										}}>
										<IconButton
											aria-label='close'
											onClick={handleCloseModal}
											icon={'UilMultiply'}
										/>
									</DialogActions>
									<DialogTitle
										variant='h3Bold'
										width={'533px'}
										align='center'
										sx={{
											p: '0 40px 24px 40px',
										}}>
										Ask a question
									</DialogTitle>

									<DialogContent
										sx={{
											display: 'flex',
											flexDirection: 'column',
											gap: '24px',
											p: '0 40px 49px 40px',
										}}>
										<DialogContentText
											variant='body'
											textAlign={'center'}
											padding={'0 49px'}
											sx={{ color: theme.colors.alpha.grey[4] }}>
											Please complete this form to log any questions, feedback, or concerns you may have.
										</DialogContentText>
										<DialogContentText
											variant='body'
											textAlign={'center'}
											padding={'0 49px'}
											sx={{ color: theme.colors.alpha.grey[4] }}>
											The InteliGro team is here to assist you and ensure you have a great experience.
										</DialogContentText>


										<FormControl error={!!errors.type && touched.type}>
											<Field
												as={RadioGroup}
												name='type'
												formLabel='Question type'
												row
												options={questionTypes}
												disabled={isSubmitting}
												helperText={
													errors.type && touched.type ? (
														<Box alignItems={'center'} display={'flex'} gap={1}>
															<Icon icon={'UilExclamationCircle'} />
															{errors.type}
														</Box>
													) : null
												}
											/>
										</FormControl>

										<DashedImageBox component='span'>
											<input
												type='file'
												style={{ display: 'none' }}
												ref={hiddenFileInput}
												accept='image/png, image/gif, image/jpeg'
												onChange={handleAddFiles}
												disabled={fileLimit}
												multiple
											/>
											{uploadedFiles.length !== 0 ? (
												<>
													{uploadedFiles.map((fileObj) => (
														<ImagePopover
															maxWidth='120px'
															maxHeight='120px'
															borderRadius='15px'
															key={fileObj.file.name}
															fileName={fileObj.file.name}
															url={fileObj.tempUrl}
															handleClearFile={clearSingleFile}
															formDisabled={isSubmitting}
														/>
													))}
													<Button
														variant='outlinedHover'
														disabled={fileLimit || isSubmitting}
														size='small'
														endIcon={'UilEditAlt'}
														onClick={() => handleFileUploadClick()}>
														Add Image
													</Button>
												</>
											) : (
												<>
													<Avatar
														sx={{
															width: '72px',
															height: '72px',
															backgroundColor: theme.colors.alpha.grey[1],
															color: theme.palette.common.black,
														}}>
														<Icon icon={'UilCamera'} color='inherit' />
													</Avatar>
													<Typography variant='body'>
														Select an image
													</Typography>
													<Button
														size='small'
														variant='outlinedHover'
														color='secondary'
														sx={{ marginLeft: 'auto' }}
														endIcon={'UilUploadAlt'}
														onClick={() => handleFileUploadClick()}>
														Upload Image
													</Button>
												</>
											)}
											<Tooltip title='Upload up to three 15MB files'>
												<IconButton
													icon={'UilInfoCircle'}
													sx={{ color: theme.colors.alpha.grey[3] }}
												/>
											</Tooltip>
										</DashedImageBox>
										<Field
											name='subject'
											as={CustomTextInput}
											label='Subject'
											placeholder='Enter the subject of your question'
											text={subject}
											inputProps={{
												disabled: isSubmitting,
											}}
											error={touched.subject && errors.subject}
											helperText={
												touched.subject && errors.subject ? (
													<Box alignItems={'center'} display={'flex'} gap={1}>
														<Icon icon={'UilExclamationCircle'} />
														{errors.subject}
													</Box>
												) : null
											}
										/>
										<Field
											name='message'
											as={CustomTextInput}
											label='Message'
											multiline
											inputProps={{
												disabled: isSubmitting,
											}}
											rows='4'
											placeholder='Enter your message'
											error={touched.message && errors.message}
											helperText={
												touched.message && errors.message ? (
													<Box alignItems={'center'} display={'flex'} gap={1}>
														<Icon icon={'UilExclamationCircle'} />
														{errors.message}
													</Box>
												) : null
											}
										/>
									</DialogContent>
									<DialogActions
										sx={{
											display: 'flex',
											justifyContent: 'center',
											p: '0 40px 40px 40px',
										}}>
										<LoadingButton
											variant='contained'
											color='primary'
											fullWidth
											type='submit'
											loading={isSubmitting}
											disabled={
												!!(touched.type && errors.type) ||
												!!(touched.message && errors.message) ||
												!!(touched.subject && errors.subject) ||
												isSubmitting
											}>
											Submit your question
										</LoadingButton>
									</DialogActions>
								</QuestionFormWrapper>
							</Form>
						);
					}}
				</Formik>
			</Dialog>
		</>
	);
};
