import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	OutlinedInput,
} from '@mui/material';
import { useState } from 'react';
import { CropsQueryType } from '../../../types/crops.types';
import { CircleIconButton } from './CircleIconButton';
import { Crop } from '../../../types/crops.types';

export const DocumentCropSelect = ({
	onChange,
	width,
	labelComponent,
	value,
	cropQuery,
	disabled,
	error,
}: {
	onChange: (value?: string | string[]) => void;
	width: number | string | undefined;
	labelComponent?: React.ComponentType<{
		id?: string;
	}>;
	value?: string | string[] | null;
	cropQuery: CropsQueryType;
	disabled?: boolean;
	error?: boolean;
}) => {
	const LabelComponent = labelComponent ?? InputLabel;
	const [showIcon, setShowIcon] = useState(false);

	const { data } = cropQuery({});

	const multiple = typeof value !== 'string';

	return (
		<FormControl sx={{ width: width ?? 'auto' }}>
			<LabelComponent id="crop-label">Crop</LabelComponent>
			<Select
				multiple={multiple}
				onChange={(e) => onChange(e.target.value)}
				sx={{ mb: 1 }}
				labelId="crop-label"
				value={value ?? ''}
				onMouseOver={() => setShowIcon(!!value)}
				onMouseOut={() => setShowIcon(false)}
				input={
					<OutlinedInput
						endAdornment={
							showIcon && <CircleIconButton onClick={() => onChange()} />
						}
						label={!labelComponent ? 'Crop' : ''}
					/>
				}
				disabled={disabled ?? false}
				error={error ?? false}
			>
				{data?.data?.map((crop: Crop) => (
					<MenuItem value={crop.id} key={`type-${crop.id}`}>
						{crop.name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
